const baudrates = document.getElementById("baudrates") as HTMLSelectElement;
const flashsizes = document.getElementById('flashsizes') as HTMLSelectElement;
const lblflashmodes = document.getElementById("lblflashmodes");
const flashmodes = document.getElementById('flashmodes') as HTMLSelectElement;
const connectButton = document.getElementById("connectButton") as HTMLButtonElement;
const disconnectButton = document.getElementById("disconnectButton") as HTMLButtonElement;
const resetButton = document.getElementById("resetButton") as HTMLButtonElement;
const consoleStartButton = document.getElementById("consoleStartButton") as HTMLButtonElement;
const consoleStopButton = document.getElementById("consoleStopButton") as HTMLButtonElement;
const eraseButton = document.getElementById("eraseButton") as HTMLButtonElement;
const programButton = document.getElementById("programButton");
const filesDiv = document.getElementById("files");
const terminal = document.getElementById("terminal");
const programDiv = document.getElementById("program");
const consoleDiv = document.getElementById("consoleDiv");
const lblBaudrate = document.getElementById("lblBaudrate");
const lblConsoleFor = document.getElementById("lblConsoleFor");
const lblConnTo = document.getElementById("lblConnTo");
const fileTable = document.getElementById("fileTable") as HTMLTableElement;
const alertDiv = document.getElementById("alertDiv");
const configButton = document.getElementById("configButton") as HTMLButtonElement;
const configSaveButton = document.getElementById("configSaveButton") as HTMLButtonElement;
const firmwareButton = document.getElementById("firmwareButton") as HTMLButtonElement;
const firmwareTable = document.getElementById("firmwareTable") as HTMLTableElement;
const firmwareChip = document.getElementById("firmwareChip");
const firmwareSelectButton = document.getElementById("firmwareSelectButton") as HTMLButtonElement;
const popupHelpButton = document.getElementById("popupHelpButton") as HTMLButtonElement;
const popupConfigHelpButton = document.getElementById("popupConfigHelpButton") as HTMLButtonElement;
const versionTabs = document.getElementById("versionTabs");

const log = document.getElementById("terminal");
const maxLogLength = 100;
	  
let espStub;

(window as any).read_firmware_available = read_firmware_available; // define as global

let ports;
let port;
let reader;

//import { ESPLoaderr, FlashOptions, LoaderOptions } from "../../../lib";

//import { ESPLoader } from "../../../src/esp_loader.ts"

import { ESPLoader, connect as ESPconnect} from "../adafruit_esptool/src/index.ts"

import { ESP_ROM_BAUD, Logger } from "../adafruit_esptool/src/const.ts";

//const esploader: ESPLoader = new ESPLoader();
//const espconnect = ESPconnect;


import { CBterminal} from "./cbterminal.ts";
import { TimeZones } from "./timezones.ts";

import { Partitions } from "./partitions.ts";
import { NVS } from "./nvs.ts";

const partitions = new Partitions();
const nvs = new NVS();


//declare let Terminal; // Terminal is imported in HTML script
declare let CryptoJS; // CryptoJS is imported in HTML script

const timeZones = new TimeZones().tz;

//const term = new Terminal({ cols: 80, rows: 15 });
const term = new CBterminal("250px");
term.open(terminal);
term.success("Welcome to Annex RDS Web Installer");
		
let device = null;
let transport: Transport;
let chip: string = null;



get_stat();

disconnectButton.style.display = "none";
eraseButton.style.display = "none";
//consoleStopButton.style.display = "none";
filesDiv.style.display = "none";
programButton.style.display = "none";
firmwareButton.style.display = "none";
configButton.style.display = "none";
 
// set the time zones in the config page
fill_time_zones();
// restore the previous settings from the local storage
get_config_from_local_storage();

const espLoaderTerminal = {
  clean() {
    term.clear();
  },
  writeLine(data) {
    term.writeln(data);
  },
  write(data) {
    term.write(data);
  },
};

function toggleUIConnected(connected) {
	if (connected == true ) {
		lblBaudrate.style.display = "none";
		lblConnTo.style.display = "block";
		baudrates.style.display = "initial";
		connectButton.style.display = "none";
		disconnectButton.style.display = "initial";
		eraseButton.style.display = "initial";
		//filesDiv.style.display = "initial";
		consoleDiv.style.display = "none";
		programButton.style.display = "initial";
		firmwareButton.style.display = "initial";
		if (chip.toLowerCase() == "esp8266") {
			lblflashmodes.style.display = "initial";
			flashmodes.style.display = "initial";
		}
		else {
			configButton.style.display = "initial";
		}
	}
	else {
		cleanUp();
		connectButton.style.display = "initial";
		disconnectButton.style.display = "none";
		eraseButton.style.display = "none";
//		consoleStopButton.style.display = "none";
		filesDiv.style.display = "none";
		programButton.style.display = "none";
		baudrates.style.display = "initial";
		lblBaudrate.style.display = "initial";
		lblConnTo.style.display = "none";
		firmwareButton.style.display = "none";
		configButton.style.display = "none";
		lblflashmodes.style.display = "none";
		flashmodes.style.display = "none";		
		consoleDiv.style.display = "initial";
	}
	
}  


connectButton.onclick = async () => {
 if (espStub) {
    await espStub.disconnect();
    await espStub.port.close();
    toggleUIConnected(false);
    espStub = undefined;
    return;
  }
  
  consoleResetButton.onclick();
  
  //baudrates.value = 115200;
  
	// let settings = {};
	// await port.getSignals(settings);
	// settings.dataTerminalReady = false;
	// await port.setSignals(settings);
	// await new Promise((resolve) => setTimeout(resolve, 100));
	// settings.dataTerminalReady = true;
	// await port.setSignals(settings);
  let esploader;
  try {
	  // esploader = await ESPconnect({
		// log: (...args) => logMsg(...args),
		// debug: (...args) => debugMsg(...args),
		// error: (...args) => errorMsg(...args),
	  // });
	  
	  const logger: Logger = {
		 log: (...args) => logMsg(...args),
		 debug: (...args) => debugMsg(...args),
		 error: (...args) => errorMsg(...args),
	  };
	  

	  // - Request a port and open a connection.
//	  const port = await navigator.serial.requestPort();

      // let's try to disconnect if previously connected
	  if (port && port.readable) {
		  try {
			await reader.cancel();
			await sleep(1000);
		  } catch(e) {port.close();}
	  }
	  else
		port = await navigator.serial.requestPort();
		
	  logger.log("Connecting...");
	  //await port.open({ baudRate: ESP_ROM_BAUD });
	  await port.open({ baudRate: baudrates.value });
	  

	  logger.log("Connected successfully.");
	  
	  esploader =  new ESPLoader(port, logger);
  }
  catch(e) {
    console.log(e);
	alert(e);
  }
  
  try {
    await esploader.initialize();
    chip = esploader.chipName;
    logMsg("Connected to " + esploader.chipName);
    logMsg("MAC Address: " + formatMacAddr(esploader.macAddr()));

    espStub = await esploader.runStub();
    toggleUIConnected(true);
    //toggleUIToolbar(true);
	
	console.log("Settings done for :" + chip);

	lblConnTo.innerHTML = "Connected to device: " + chip;
	

	// look for the versions available for this chip

	const flash_size = espStub.flashSize;
	flashsizes.value = flash_size;
	const mod = chip.toLowerCase();

	read_firmware_available(chip); // read the versions available
	
	switch(mod) {
		case "esp32" : 
		  versionTabs.innerHTML =   
		  `
				<ul class="nav nav-pills">
					<li class="nav-item active">
						<a class="nav-link" data-toggle="tab" onclick="read_firmware_available('esp32')">ESP32</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" data-toggle="tab" onclick="read_firmware_available('esp32_cam')">ESP32 CAM</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" data-toggle="tab" onclick="read_firmware_available('esp32_epaper')">ESP32 EPAPER</a>
					</li>
				</ul>	
		  `;
		break;
		
		default:
			versionTabs.innerHTML = "";
		break;
	}
	
    espStub.addEventListener("disconnect", () => {
      toggleUIConnected(false);
      espStub = false;
    });
  } catch (err) {
    console.log(err);
	try {
		await esploader.disconnect();
		await espStub.port.close();
	} catch(e) {}
    //throw err;
  }
};

function read_firmware_available(chip) {
	// look for the versions available for this chip

	const mod = chip.toLowerCase();
	const url = 'https://updates.cicciocb.com/ver.php?chip=' + mod;

	firmwareChip.innerHTML = mod.toUpperCase();
	const remoteFileURL = url;
	readFileFromRemoteServer(remoteFileURL, (progress) => {
	  console.log(`Download progress: ${progress.toFixed(2)}%`);
	}, (text) => {
	  if (text !== null) {
		//console.log('Text content:', text);
		// split the lines with "<br>"
		const arr:array = text.split("<br>");
		
		var c = "";
		for (let i=arr.length-1; i >= 0; i--) {
		   if (!arr[i]) continue;
		   var line:array = arr[i].split("|");
		   c += `<tr><th>${line[0]}</th><td>${line[1]}</td><td>${line[4]}</td><td>${line[3]}</td>`;
		   c += `<td id="url" hidden>${line[2]}</td></tr>`
		}
		
		firmwareTable.innerHTML = c;
		var lines:array = firmwareTable.getElementsByTagName("tr");
		for (let el of lines) {
		  el.addEventListener("click", selRow);
		}
		
	  } else {
		console.log('Failed to read the remote file.');
		return;
	  }
	});	
}


disconnectButton.onclick = async () => {
  if (espStub) {
    await espStub.disconnect();
    await espStub.port.close();
    toggleUIConnected(false);
    espStub = undefined;
    return;
  }

  cleanUp();
  return;

};

eraseButton.onclick = async () => {
  if (!confirm("This will erase completly your module!\nAre you sure?")) return;
  eraseButton.disabled = true;
  try {
    //await esploader.erase_flash();
      logMsg("Erasing flash memory. Please wait...");
      let stamp = Date.now();
      await espStub.eraseFlash();
      logMsg("Finished. Took " + (Date.now() - stamp) + "ms to erase.");
  } catch (e) {
    console.error(e);
    term.error(`Error: ${e.message}`);
  } finally {
    eraseButton.disabled = false;
  }
};

function addNewRow() {
  const rowCount = fileTable.rows.length;
  const row = fileTable.insertRow(rowCount);
  
  //Column 0 - Description
  const cell0 = row.insertCell(0);
  const element0 = document.createElement("label");
  element0.innerText = "ota";
  cell0.appendChild(element0);
  row.description = element0;
  
  //Column 1 - Offset
  const cell1 = row.insertCell(1);
  const element1 = document.createElement("label");
  element1.id = "offset" + rowCount;
  element1.innerText = "0x1000";
  cell1.appendChild(element1);
  row.offset = element1;

  // Column 2 - File
  const cell2 = row.insertCell(2);
  const element2 = document.createElement("label");
  //element2.type = "file";
  element2.type = "text";
  element2.id = "selectFile" + rowCount;
  element2.innerText = "----"
  element2.name = "selected_File" + rowCount;
  cell2.appendChild(element2);
  row.filename = element2;
  
  // Column 3  - Progress
  const cell3 = row.insertCell(3);
  //cell3.style.width="1250px";
  cell3.style.paddingBottom="0px";
  cell3.innerHTML = `
<div class="progress">
  <div id="progress1" class="progress-bar" role="progressbar" style="width:0%"></div>
</div>
<div class="progress">
  <div  id="progress2" class="progress-bar" role="progressbar" style="background-color:orange;width: 0%"></div>
</div>
`;
  row.progress1 = cell3.querySelector('#progress1');
  row.progress2 = cell3.querySelector('#progress2');

  //Column 4 - Checkbox
  const cell4 = row.insertCell(4);
  const element4 = document.createElement("input");
  element4.type = "checkbox";
  element4.checked = false;
  cell4.appendChild(element4);
  row.selected = element4;
}

function removeRow(row) {
  const rowIndex = Array.from(fileTable.rows).indexOf(row);
  fileTable.deleteRow(rowIndex);
}

// to be called on disconnect - remove any stale references of older connections if any
function cleanUp() {
  device = null;
  transport = null;
  chip = null;
  
  while (fileTable.rows.length > 1) {
    console.log(fileTable.rows.length);
    fileTable.deleteRow(1);
  }
}




function validate_program_inputs() {
  const offsetArr = [];
  const rowCount = fileTable.rows.length;
  let row;
  let offset = 0;
  let fileData = null;

  // check for mandatory fields
  for (let index = 1; index < rowCount; index++) {
    row = fileTable.rows[index];
	if(row.selected.checked == false) continue;
	
    //offset fields checks
    const offSetObj = row.offset;
    offset = parseInt(offSetObj.innerText);

    // Non-numeric or blank offset
    if (Number.isNaN(offset)) return "Offset field in row " + index + " is not a valid address!";
    // Repeated offset used
    else if (offsetArr.includes(offset)) return "Offset field in row " + index + " is already in use!";
    else offsetArr.push(offset);

    
    fileData = row.data;
    //if (fileData == null) return "No file loaded for row " + index + "!";
	if (fileData == null) return "No file loaded for row " + row.description.innerText + " !";
  }
  return "success";
}

function stringToHexASCII(inputString) {
  let hexString = '';
  for (let i = 0; i < inputString.length; i++) {
    const charCode = inputString.charCodeAt(i);
    const hexCode = charCode.toString(16).toUpperCase();
    hexString += '' + hexCode.padStart(2, '0');
  }
  return hexString;
}
const fileArray = [];
  
programButton.onclick = async () => {



		  $('#donationModal').modal({
			backdrop: 'static',  // This prevents the modal from closing when clicking outside
			keyboard: false      // This prevents the modal from closing when pressing the Esc key
		  });
		  $('#donationModal')[0].callback = "program";
}		  
async function programModule() {
  if (!confirm(` 
A little reminder before flashing:
- Are you sure that you selected the right flash memory size?
  The risk is to wipe the existing data on the internal disk
- Are you sure you selected the right version?
  Selecting the wrong version the module will not work 
- If the module has already been flashed with Annex, select just the firmware  
Are you sure?`)) return;

  const alertMsg = document.getElementById("alertmsg");
  const err = validate_program_inputs();

  if (err != "success") {
    alertMsg.innerHTML = "<strong>" + err + "</strong>";
    alertDiv.style.display = "block";
    return;
  }

  const baud = parseInt(baudrates.value)
  //await espStub.setBaudrate(baud);
  
  // Hide error message
  alertDiv.style.display = "none";

  const fileArray = [];
  const progressBars = [];

  for (let index = 1; index < fileTable.rows.length; index++) {
    const row = fileTable.rows[index];

    const offSetObj = row.offset;
    const offset = parseInt(offSetObj.innerText);

    const fileObj = row;
    const progressBar = row.progress2; 
	
    progressBar.style.width = "0%";
    progressBars.push(progressBar);

	if(row.selected.checked == false) continue;

    if (chip.toLowerCase() == "esp8266") {
		// now will try to set the "header" if the magic number is found (E9 01)
		// in this case the flashMode, the Flash Size and the flash freq will be set.
		if ( (fileObj.data[0] == '\xe9') && (fileObj.data[1] == '\x01') ) {
			// set the flash mode and the freq 
			const flash_mode = flashmodes.value;
			const flash_freq = "80m";
			let f_mode = 0;
			let f_size = 0;
			let f_freq = 0;
		
			switch (flash_mode) {
				case "qio": f_mode = 0; break;
				case "qout": f_mode = 1; break;
				case "dio": f_mode = 2; break;
				case "dout": f_mode = 3; break;
			}
			
			// set the header parameters
			switch (flashsizes.value) {
				case "1MB": f_size = 2; break;
				case "2MB": f_size = 3; break;
				case "4MB": f_size = 4; break;
				case "8MB": f_size = 8; break;
				case "16MB": f_size = 9; break;
			}

			switch (flash_freq) {
				case "40m": f_freq = 0; break; 
				case "26m": f_freq = 1; break; 
				case "20m": f_freq = 2; break; 
				case "80m": f_freq = 0xf; break; 
			}

			const f_freq_size = (f_size << 4) | f_freq; // shift the high four bits		
			term.success("Header found");
			console.log("Header found");
			// patch the original bin
			fileObj.data = nvs.replaceAt(fileObj.data, 2, String.fromCharCode(f_mode));
			fileObj.data = nvs.replaceAt(fileObj.data, 3, String.fromCharCode(f_freq_size));
			
			// now we should compute the SHA256

			// look if the SHA is present or not (check the byte 15 + 8 = 23) if 1 -> SHA present otherwise don't
			
			const is_present_hash = fileObj.data.charCodeAt(23);
			
			// Convert the binary string in a WordArray object		
			var pos_hash = fileObj.data.length;
			if (is_present_hash) pos_hash = pos_hash - 32;
			
			const byteArray = [];
			for (let i = 0; i < pos_hash; i++) {  // remove 32 chars from the hash
			  byteArray.push(fileObj.data.charCodeAt(i));
			}
			const wordArray = CryptoJS.lib.WordArray.create(Uint8Array.from(byteArray));

			const hash = CryptoJS.SHA256(wordArray);

			console.log(hash.toString(CryptoJS.enc.Hex)); // 
			const binaryHash = hash.toString(CryptoJS.enc.Latin1);
			
			// patch the original bin
			fileObj.data = nvs.replaceAt(fileObj.data, pos_hash, binaryHash);
		}
	}
	else {
		// now will try to set the "header" if the magic number is found (E9 03)
		// in this case the flashMode, the Flash Size and the flash freq will be set.
		if ( (fileObj.data[0] == '\xe9') && (fileObj.data[1] == '\x03') ) {
			// set the flash mode and the freq to 'dio' and '80m'
			const flash_mode = "dio";
			const flash_freq = "80m";
			let f_mode = 0;
			let f_size = 0;
			let f_freq = 0;
		
			switch (flash_mode) {
				case "qio": f_mode = 0; break;
				case "qout": f_mode = 1; break;
				case "dio": f_mode = 2; break;
				case "dout": f_mode = 3; break;
			}
			
			// set the header parameters
			switch (flashsizes.value) {
				case "4MB": f_size = 2; break;
				case "8MB": f_size = 3; break;
				case "16MB": f_size = 4; break;
				case "32MB": f_size = 5; break;
				case "64MB": f_size = 6; break;
				case "128MB": f_size = 7; break;
			}

			switch (flash_freq) {
				case "40m": f_freq = 0; break; 
				case "26m": f_freq = 1; break; 
				case "20m": f_freq = 2; break; 
				case "80m": f_freq = 0xf; break; 
			}

			const f_freq_size = (f_size << 4) | f_freq; // shift the high four bits		
			term.success("Header found");
			console.log("Header found");
			// patch the original bin
			fileObj.data = nvs.replaceAt(fileObj.data, 2, String.fromCharCode(f_mode));
			fileObj.data = nvs.replaceAt(fileObj.data, 3, String.fromCharCode(f_freq_size));
			
			// now we should compute the SHA256

			// look if the SHA is present or not (check the byte 15 + 8 = 23) if 1 -> SHA present otherwise don't
			
			const is_present_hash = fileObj.data.charCodeAt(23);
			
			// Convert the binary string in a WordArray object		
			var pos_hash = fileObj.data.length;
			if (is_present_hash) pos_hash = pos_hash - 32;
			
			const byteArray = [];
			for (let i = 0; i < pos_hash; i++) {  // remove 32 chars from the hash
			  byteArray.push(fileObj.data.charCodeAt(i));
			}
			const wordArray = CryptoJS.lib.WordArray.create(Uint8Array.from(byteArray));

			const hash = CryptoJS.SHA256(wordArray);

			console.log(hash.toString(CryptoJS.enc.Hex)); // 
			const binaryHash = hash.toString(CryptoJS.enc.Latin1);
			
			// patch the original bin
			fileObj.data = nvs.replaceAt(fileObj.data, pos_hash, binaryHash);
		}	
	}
    fileArray.push({ data: fileObj.data, address: offset, index:index-1, progressBar: progressBar});
  }
  term.success("\nFlash process started ....")
  for (let file of fileArray) {
    try {
	  let contents = binaryStringToArrayBuffer(file.data);
      let offset = file.address;
	  let progressbar = file.progressBar;
      await espStub.flashData(
        contents,
        (bytesWritten, totalBytes) => {
          progressbar.style.width =
            Math.floor((bytesWritten / totalBytes) * 100) + "%";
			logMsg("Flashing " + Math.floor((bytesWritten / totalBytes) * 100) + "%");
        },
        offset
      );
      await sleep(100);
    } catch (e) {
      errorMsg(e);
	  return;
    }	
  }
  term.success("\nFlash process completed!")
  //term.warning("You can now click on disconnect and press reset on your device to run the new firmware.")
  term.warning(`
The module will be reset, and the console will establish a connection to the module
in order to look at the startup message. 
If no information is displayed, it will be necessary to manually restart the module
by pressing the "reset" button on the module, or by physically disconnecting and 
reconnecting it from the PC.`);
  
  //flashsizes.value
  //fileTable.rows[1].description.innerHTML
  
  // determines the stats of the flash
  //chip = chip;
  //flash_size = flashsizes.value;
  //firmware
  //full means all the checkbox selected
  let firmware = "";
  let full = true;
  for (let i=1; i<fileTable.rows.length; i++) {
    if (fileTable.rows[i].description.innerHTML == "firmware")
	{
		console.log("firmware found");
		firmware = fileTable.rows[i].filename.innerText;
	}
	if (fileTable.rows[i].selected.checked == false)
		full = false;
  }
  
  
  if (firmware != "") 
	send_stat(chip, flashsizes.value, firmware, full);
	
  //termopen.onclick("restart");
  await disconnectButton.onclick();
  openPort();	
};


// create the partition fileTable partition
function create_partition_table() {
  if (chip.toLowerCase() == "esp8266") return;
  
  var size;
  const flashsize = flashsizes.value;
  switch (flashsize) {
	case "8MB":
		size = 0x500000;
	break;
	case "16MB":
		size = 0xD00000;
	break;
	case "32MB":
		size = 0x1D00000;
	break;
	default:
	case "4MB":
		size = 0x100000;
	break;
  }
  var new_partitions_bin = partitions.update_partition_size("ffat", size);
  AddNewBinary("Partitions table " + flashsize, "0x8000", new_partitions_bin, false, "Partition table");
}

flashsizes.onchange = async () => {
  create_partition_table();
};

// create the nvs partition and put in the list
// if the partition already exists, replace it
configSaveButton.onclick = async () => {
  filesDiv.style.display = "initial";
  // Get the values of each input field and store them in variables
  const stationName = document.getElementById('Station_Name').value;
  const stationPass = document.getElementById('Station_Pass').value;
  const apName = document.getElementById('AP_Name').value;
  const apPass = document.getElementById('AP_Pass').value;
  const apChannel = document.getElementById('AP_Chan').value;
  const ipAddress = document.getElementById('IP').value;
  const subnetMask = document.getElementById('Subnet').value;
  const gateway = document.getElementById('Gateway').value;
  const port = document.getElementById('Port').value;
  const timeZone = document.getElementById('Time_Zone').value;

  // // Use the values as needed (e.g., log them to the console)
  // console.log('Station Name:', stationName);
  // console.log('Station Password:', stationPass);
  // console.log('Access Point Name:', apName);
  // console.log('Access Point Password:', apPass);
  // console.log('Access Point Channel:', apChannel);
  // console.log('IP Address:', ipAddress);
  // console.log('Subnet Mask:', subnetMask);
  // console.log('Gateway:', gateway);
  // console.log('Port:', port);
  // console.log('Time Zone:', timeZone);
  
  nvs.nvs_update_string("Station_Name:", stationName);
  nvs.nvs_update_string("Station_Pass:", stationPass);
  nvs.nvs_update_string("AP_Name:", apName);
  nvs.nvs_update_string("AP_Pass:", apPass);
  nvs.nvs_update_string("AP_Chan:", apChannel);

  nvs.nvs_update_string("IP:", ipAddress);
  nvs.nvs_update_string("Subnet:", subnetMask);
  nvs.nvs_update_string("Gateway:", gateway);
  nvs.nvs_update_string("Port:", port);
  nvs.nvs_update_string("Time_Zone:", timeZone);
  const new_nvs_bin:string = nvs.nvs_base;

  //console.log(new_nvs_bin);
  AddNewBinary("Configuration", "0x9000", new_nvs_bin, true, "nvs"); // select the line by default
  save_config_to_local_storage();
  
};


function AddNewFile(description, offset, url, checked:boolean=false) {
	var i;

    // check if the partition name already exists
	for (i=1; i<fileTable.rows.length; i++) {
	  if ( (fileTable.rows[i].description.innerText == description) ||
		   (fileTable.rows[i].offset.innerText == offset) )
		break;
	}
	if (i >= fileTable.rows.length)
		addNewRow();

	const row = fileTable.rows[i];	
	row.data = null;
	row.description.innerText = description;
	row.offset.innerText = offset;
	//shows only the filename and not the full path
	const lastIndex = url.lastIndexOf("/");
	const filename = url.substring(lastIndex + 1);
	row.filename.innerText = filename;
    
	//generate random number to bypass browser cache
	const min = 100000; // Minimum value (inclusive)
	const max = 999999; // Maximum value (inclusive)
	const rand =  Math.floor(Math.random() * (max - min + 1)) + min;
	
	readFileFromRemoteServer(url + "?" + rand, (progress) => {
	   row.progress1.style.width = progress+"%";
	}, (binaryString) => {
	 if (binaryString !== null) {
		row.data = binaryString;
	 } else {
	  console.log('Failed to read the remote file.');
	 }
	});
	row.selected.checked = checked;
	return i;
}

function AddNewBinary(description, offset, binaryString, checked:boolean=false, description2=null) {
	var i;

    // check if the partition name already exists
	for (i=1; i<fileTable.rows.length; i++) {
	  if ( (fileTable.rows[i].description.innerText == description) ||
		   (fileTable.rows[i].offset.innerText == offset) )
		break;
	}
	if (i >= fileTable.rows.length)
		addNewRow();

	const row = fileTable.rows[i];		
	description2 == null ? row.description.innerText = description : row.description.innerText = description2;
	row.offset.innerText = offset;
	row.filename.innerText = description;
	row.data = binaryString;
	row.progress1.style.width = "100%";
	row.selected.checked = checked;
    return i;
}

function blobToBinaryString(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = () => {
      reject(new Error('Error reading the Blob as binary string'));
    };

    reader.readAsBinaryString(blob);
  });
}

// read a file from a remote server;
function readFileFromRemoteServer(url, progressCallback, callback) {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';

  xhr.onprogress = function (event) {
    if (event.lengthComputable) {
      const percentComplete = (event.loaded / event.total) * 100;
      progressCallback(percentComplete);
    }
  };

  xhr.onreadystatechange = function () {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        const reader = new FileReader();
        reader.onload = function () {
          const binaryString = reader.result;
          callback(binaryString);
        };
        reader.readAsBinaryString(xhr.response);
      } else {
        console.error(`Failed to fetch file from ${url}. Status: ${xhr.status}`);
		term.error(`Failed to fetch file from ${url}. Status: ${xhr.status}`);
        callback(null);
      }
    }
  };

  xhr.open('GET', url, true);
  xhr.send();
}

function fill_time_zones() {
	const selectElement = document.getElementById("Time_Zone");

	// Function to add options to the select element
	function addOption(text, value) {
		const option = document.createElement("option");
		option.text = text;
		option.value = value;
		selectElement.appendChild(option);
	}

	// Adding options based on the timeZones array
	for (let i = 0; i < timeZones.length; i += 2) {
		const timeZone = timeZones[i];
		const offset = timeZones[i + 1];
		addOption(timeZone, offset);
	}
}

// Retrieve form values from local storage and populate the form
function get_config_from_local_storage() {
  const form = document.getElementById("configFormModal");
  const inputs = form.getElementsByTagName("input");
  for (let i = 0; i < inputs.length; i++) {
    const inputId = inputs[i].id;
    const storedValue = localStorage.getItem(inputId);
    if (storedValue !== null) {
      inputs[i].value = storedValue;
    }
  }
  const selects = form.getElementsByTagName("select");
  for (let i = 0; i < selects.length; i++) {
    const selectId = selects[i].id;
	const storedValue = localStorage.getItem(selectId);
    if (storedValue !== null) {
	  selects[i].selectedIndex = storedValue;
    }	  
  }	
}

function save_config_to_local_storage() {
  const form = document.getElementById("configFormModal");
  const inputs = form.getElementsByTagName("input");
  for (let i = 0; i < inputs.length; i++) {
    const inputId = inputs[i].id;
    const inputValue = inputs[i].value;
    localStorage.setItem(inputId, inputValue);
  }
  const selects = form.getElementsByTagName("select");
  for (let i = 0; i < selects.length; i++) {
    const selectId = selects[i].id;
    const selectValue = selects[i].selectedIndex;
    localStorage.setItem(selectId, selectValue);
  }	
}


firmwareButton.onclick = async () => {
// empty
}

function selRow(e) {
  $(e.target.parentElement.parentElement.childNodes).removeClass("selected")
  $(e.target.parentElement).addClass("selected");
}

firmwareSelectButton.onclick = async (e:event) => {

  filesDiv.style.display = "initial";
  for (let el of firmwareTable.childNodes) {
    if (el.classList.contains("selected") == true) {
	  //console.log(el.querySelector("#url"));
	  var firmware_url = el.querySelector("#url").innerText;
	  // now select the batch of files that compose the configuration for each chip
	  		
	 if (chip.toLowerCase() == "esp8266") {
		AddNewFile("firmware", "0x0", firmware_url, true);
	 }
	 else {
	 	create_partition_table();

		AddNewFile("firmware", "0x10000", firmware_url, true);
	 
		AddNewFile("boot_app0", "0xe000", 'https://updates.cicciocb.com/boot/boot_app0.bin');
	 }
	 
	// the bootloader and the OTA depends on the chip
     
	 switch(chip.toLowerCase()) {
		case "esp32":
			AddNewFile("bootloader", "0x1000", 'https://updates.cicciocb.com/boot/esp32/bootloader_dio_80m.bin');
			AddNewFile("OTA", "0x240000", 'https://updates.cicciocb.com/ota/Annex32_OTA_updater_v1.9_by_ciccioCB_(c)_2024.bin');
		break;
		
		case "esp32-c3":
			AddNewFile("bootloader", "0x0", 'https://updates.cicciocb.com/boot/esp32c3/bootloader_dio_80m.bin');
			AddNewFile("OTA", "0x240000", 'http://updates.cicciocb.com/ota/Annex32-C3_dio_OTA_updater_v1.9_by_ciccioCB_(c)_2024.bin');
		break;
		
		case "esp32-s2":
			AddNewFile("bootloader", "0x1000", 'https://updates.cicciocb.com/boot/esp32s2/bootloader_dio_80m.bin');
			AddNewFile("OTA", "0x240000", 'http://updates.cicciocb.com/ota/Annex32-S2_qio_OTA_updater_v1.9_by_ciccioCB_(c)_2024.bin');
		break;
		
		case "esp32-s3":
		    if (firmware_url.includes("opi_opi")) {
				AddNewFile("bootloader", "0x0", 'https://updates.cicciocb.com/boot/esp32s3/bootloader_opi_80m.bin');
				AddNewFile("OTA", "0x240000", 'http://updates.cicciocb.com/ota/Annex32-S3_opi_OTA_updater_v1.9_by_ciccioCB_(c)_2024.bin');
			}
			else {
				AddNewFile("bootloader", "0x0", 'https://updates.cicciocb.com/boot/esp32s3/bootloader_dio_80m.bin');
				AddNewFile("OTA", "0x240000", 'http://updates.cicciocb.com/ota/Annex32-S3_qio_OTA_updater_v1.9_by_ciccioCB_(c)_2024.bin');	
			}
		break;

		case "esp32_cam":
			AddNewFile("bootloader", "0x1000", 'https://updates.cicciocb.com/boot/esp32/bootloader_dio_80m.bin');
			AddNewFile("OTA", "0x240000", 'https://updates.cicciocb.com/ota/Annex32_OTA_updater_v1.9_by_ciccioCB_(c)_2024.bin');
		break;
		
		case "esp32_epaper":
			AddNewFile("bootloader", "0x1000", 'https://updates.cicciocb.com/boot/esp32/bootloader_dio_80m.bin');
			AddNewFile("OTA", "0x240000", 'https://updates.cicciocb.com/ota/Annex32_OTA_updater_v1.9_by_ciccioCB_(c)_2024.bin');
		break;
		
		default:

		break;
	 }
	}
  }
}

function get_stat() {
  if (window.location.href.indexOf("//localhost") != -1) return;
  fetch("../stat/stat.php")
    .then(response => response.text())
    .then(data => {
		$('#statDiv').html(data);
    })
    .catch(error => {
        console.error('Error fetching PHP file:', error);
    });
}

function send_stat(chip, flash_size, firmware, full) {
  if (window.location.href.indexOf("//localhost") != -1) return;
  let flash = (full == true) ? "full" : "update";
  const args = `chip=${chip}&flash_size=${flash_size}&firmware=${firmware}&type=${flash}`;
  fetch("../stat/send_stat.php?" + args)
    .then(response => response.text())
    .then(data => {
		console.log("putvers " + data);
    })
    .catch(error => {
        console.error('Error fetching PHP file:', error);
    });
}



// document.getElementById("myconnect").addEventListener("click", clickConnect);

// /**
 // * @name clickConnect
 // * Click handler for the connect/disconnect button.
 // */
// async function clickConnect() {

  // if (espStub) {
    // await espStub.disconnect();
    // await espStub.port.close();
    // toggleUIConnected(false);
    // espStub = undefined;
    // return;
  // }

  ////log.innerHTML="";
  ////const esploaderMod = await window.esptoolPackage;

  // const esploader = await ESPconnect({
    // log: (...args) => logMsg(...args),
    // debug: (...args) => debugMsg(...args),
    // error: (...args) => errorMsg(...args),
  // });
  // try {
    // await esploader.initialize();

    // logMsg("Connected to " + esploader.chipName);
    // logMsg("MAC Address: " + formatMacAddr(esploader.macAddr()));

    // espStub = await esploader.runStub();
    ////toggleUIConnected(true);
    ////toggleUIToolbar(true);
    // espStub.addEventListener("disconnect", () => {
      // toggleUIConnected(false);
      // espStub = false;
    // });
  // } catch (err) {
    // await esploader.disconnect();
    // throw err;
  // }
// }


function logMsg(text) {
  //log.innerHTML += text + "<br>";
  term.writeln(text);
  return;
  // Remove old log content
  if (log.textContent.split("\n").length > maxLogLength + 1) {
    let logLines = log.innerHTML.replace(/(\n)/gm, "").split("<br>");
    log.innerHTML = logLines.splice(-maxLogLength).join("<br>\n");
  }

  //if (autoscroll.checked) {
    log.scrollTop = log.scrollHeight;
  //}
}

function debugMsg(...args) {
  function getStackTrace() {
    let stack = new Error().stack;
    //console.log(stack);
    stack = stack.split("\n").map((v) => v.trim());
    stack.shift();
    stack.shift();

    let trace = [];
    for (let line of stack) {
      line = line.replace("at ", "");
      trace.push({
        func: line.substr(0, line.indexOf("(") - 1),
        pos: line.substring(line.indexOf(".js:") + 4, line.lastIndexOf(":")),
      });
    }

    return trace;
  }

  let stack = getStackTrace();
  stack.shift();
  let top = stack.shift();
  let prefix =
    '<span class="debug-function">[' + top.func + ":" + top.pos + "]</span> ";
  for (let arg of args) {
    if (arg === undefined) {
      logMsg(prefix + "undefined");
    } else if (arg === null) {
      logMsg(prefix + "null");
    } else if (typeof arg == "string") {
      logMsg(prefix + arg);
    } else if (typeof arg == "number") {
      logMsg(prefix + arg);
    } else if (typeof arg == "boolean") {
      logMsg(prefix + (arg ? "true" : "false"));
    } else if (Array.isArray(arg)) {
      logMsg(prefix + "[" + arg.map((value) => toHex(value)).join(", ") + "]");
    } else if (typeof arg == "object" && arg instanceof Uint8Array) {
      logMsg(
        prefix +
          "[" +
          Array.from(arg)
            .map((value) => toHex(value))
            .join(", ") +
          "]"
      );
    } else {
      logMsg(prefix + "Unhandled type of argument:" + typeof arg);
      console.log(arg);
    }
    prefix = ""; // Only show for first argument
  }
}

function errorMsg(text) {
  term.error(text);
  //logMsg('<span class="error-message">Error:</span> ' + text);
  console.error(text);
}

function formatMacAddr(macAddr) {
  return macAddr
    .map((value) => value.toString(16).toUpperCase().padStart(2, "0"))
    .join(":");
}
function ucWords(text) {
  return text
    .replace("_", " ")
    .toLowerCase()
    .replace(/(?<= )[^\s]|^./g, (a) => a.toUpperCase());
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function binaryStringToArrayBuffer(binaryString: string): ArrayBuffer {
  const length = binaryString.length;
  const buffer = new ArrayBuffer(length);
  const view = new Uint8Array(buffer);

  for (let i = 0; i < length; i++) {
    view[i] = binaryString.charCodeAt(i);
  }

  return buffer;
}

baudrates.addEventListener("change", changeBaudRate);
/**
 * @name changeBaudRate
 * Change handler for the Baud Rate selector.
 */
async function changeBaudRate() {
  if (espStub) {
      let baud = parseInt(baudrates.value);
      await espStub.setBaudrate(baud);
  }
}

popupConfigHelpButton.onclick = async () => {

  var helpModalContent = $("#confighelpModal").html();
  // Remove the buttons from the modal content
  var contentWithoutButtons = $(helpModalContent).find('.modal-footer').remove().end();
  // Remove the style attribute from the modal body
  $(contentWithoutButtons).find('.modal-body').removeAttr('style');

  var css = '<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">';
  //var popupWindow = window.open('', '_blank', 'width=' + contentWidth ,',height=' + contentHeight);
  var popupWindow = window.open('', '_blank', 'width=700,height=700');
  popupWindow.document.open();

  popupWindow.document.write(css);

  popupWindow.document.write(contentWithoutButtons.prop('outerHTML'));
	
  popupWindow.document.close();
  $('#confighelpModal').modal('hide'); // close the form
}

popupHelpButton.onclick = async () => {

  var helpModalContent = $("#helpModal").html();
  // Remove the buttons from the modal content
  var contentWithoutButtons = $(helpModalContent).find('.modal-footer').remove().end();
  // Remove the style attribute from the modal body
  $(contentWithoutButtons).find('.modal-body').removeAttr('style');

  var css = '<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">';
  //var popupWindow = window.open('', '_blank', 'width=' + contentWidth ,',height=' + contentHeight);
  var popupWindow = window.open('', '_blank', 'width=700,height=700');
  popupWindow.document.open();

  popupWindow.document.write(css);

  popupWindow.document.write(contentWithoutButtons.prop('outerHTML'));
	
  popupWindow.document.close();
  $('#helpModal').modal('hide'); // close the form
}
//// CONNECTION ALIVE STUFF ////

//	setInterval(check_connection, 250);
	
	function check_connection() {
		var status;
		if (port === undefined)
			status = false;
		else
			if (port.readable == null)
				status = false;
			else
				status = true;
		connected.style.fill = status ? 'green' : 'red';
		connected.checked = status;
	}
	
//// TERMINAL STUFF ////
const consoleOpenButton = document.getElementById("consoleOpenButton") as HTMLButtonElement;
const consoleCloseButton = document.getElementById("consoleCloseButton") as HTMLButtonElement;
const consoleResetButton = document.getElementById("consoleResetButton") as HTMLButtonElement;

consoleCloseButton.style.display = "none";	

consoleResetButton.onclick = async () => {
		let settings = {};
		await port.getSignals(settings);
		settings.dataTerminalReady = false;
		settings.requestToSend  = true;
		await port.setSignals(settings);
		await new Promise((resolve) => setTimeout(resolve, 100));
		settings.dataTerminalReady = false;
		settings.requestToSend  = false;
		await port.setSignals(settings);
		
		await new Promise((resolve) => setTimeout(resolve, 300));
		
		await port.getSignals(settings);
		settings.dataTerminalReady = false;
		settings.requestToSend  = false;
		await port.setSignals(settings);
		await new Promise((resolve) => setTimeout(resolve, 100));
		settings.dataTerminalReady = true;
		settings.requestToSend  = false;
		await port.setSignals(settings);		
		
	}
	
consoleCloseButton.onclick = async () => {
	await reader.cancel();
	consoleOpenButton.style.display = "initial";
	consoleCloseButton.style.display = "none";
}
	
consoleOpenButton.onclick = async () => {
	console.log("connect serial");
	// Get all serial ports the user has previously granted the website access to.
	ports = await navigator.serial.getPorts();  // get the list of the ports paired
	port = await navigator.serial.requestPort();  // get the name of the port chosen
	const portInfo = port.getInfo();
	
	port.addEventListener('connect', (event) => {
	  console.log('Port is now open');
	});

	port.addEventListener('disconnect', (event) => {
	  console.log('Port is now closed');
	});
		
	
	console.log(portInfo);
	openPort();
}


	async function openPort() {
		try 
		{
			//port.ondisconnect = closeSerial;
			await port.open({ baudRate: 115200, dataBits:8, stopBits:1, parity: "none", flowControl:"none", buffersize: 1024 });	

	consoleOpenButton.style.display = "none";
	consoleCloseButton.style.display = "initial";
	
			let settings = {};
			//settings.dataTerminalReady = dtr.checked;
			//settings.requestToSend = rts.checked;
			//await port.setSignals(settings);
			consoleResetButton.onclick();
			await listenToPort();
			console.log(await navigator.serial.getPorts());
		}
		catch(e) {
		if (e.code != 19)
			alert(e + " " + e.code);
			console.log(e);
		}
	}

   async function listenToPort() {
        const textDecoder = new TextEncoderStream();
        const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
        reader = textDecoder.readable.getReader();

        // Listen to data coming from the serial device.
        while (true) {
            const { value, done } = await reader.read();
            if (done) {
                // Allow the serial port to be closed later.
                //console.log('[readLoop] DONE', done);
                reader.releaseLock();
                break;
            }
            // value is an array

			var v3 = convertMe(value);
		    console.log("receive:" + v3); 
			//composeBuffer(v3);
		    term.write(v3); 
        }
		console.warn("cancelled");
		
		const textEncoder = new TextEncoderStream();
		const writableStreamClosed = textEncoder.readable.pipeTo(port.writable);
		const writer = textEncoder.writable.getWriter();

		//reader.cancel();
		await readableStreamClosed.catch(() => {  });

		writer.close();
		await writableStreamClosed;

		await port.close();
		console.log("Closed");
    }

	// function sleep(ms) {
		// return new Promise(
		// resolve => setTimeout(resolve, ms)
		// );
	// }

	function convertMe(value) {
		var str = new TextDecoder().decode(value);
		var ar = str.split(",")
		var s = "";
		for (var i=0; i<ar.length; i++)
		  s += String.fromCharCode(Number(ar[i]));
		return s;
	}


/// Donation STUFF

  $(document).ready(function() {
	// Show the modal when the donation button is clicked
	$('#donationButton').on('click', function() {
	  $('#donationModal').modal({
		backdrop: 'static',  // This prevents the modal from closing when clicking outside
		keyboard: false      // This prevents the modal from closing when pressing the Esc key
	  });
	  $('#donationModal')[0].callback = "none";
	});

	// Close the modal when the close button is clicked
	$('#closeModal, .modal-header .close').on('click', function() {
	  $('#donationModal').modal('hide');
	  if ($('#donationModal')[0].callback == "program") {
		programModule();
	  }
	});

	// // Close the modal when any donation button is clicked
	// $('.donate-btn').on('click', function() {
	  // close_donate_modal();
	// });

  });